#restaurant-detail-assign-menu-modal {
  min-height: 80vh;

  #menu-selector-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    flex-grow: 1;
    overflow: hidden;
    #menu-item-search {
      margin-bottom: 16px;
      .ant-form-item {
        margin-bottom: 8px;
      }
      .ant-input-group-wrapper {
        top: 0;
      }
    }
    #category-search-wrapper {
      flex-grow: 1;
      height: 100%;
      overflow-y: hidden;
      overflow-x: hidden;

      .category-column-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        max-height: calc(100vh - 263px);
        .category-column-content {
          margin-top: 8px;
          border: solid 1px rgba(0, 0, 0, 0.15);
          border-radius: 2px;
          height: 100%;
          overflow: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
          .category-menu-item {
            width: 100%;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            border: none;
            border-bottom: solid 1px rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            padding: 16px 12px;
            &.--selected {
              background-color: #e6f7ff;
              background-blend-mode: multiply;
            }
            .menu-item-detail {
              word-break: break-word;
              .menu-item-note {
                color: rgba(0, 0, 0, 0.25);
                font-size: 12px;
                line-height: 12px;
                word-break: break-word;
              }
            }
          }
          .loading-icon {
            position: relative;
            left: calc(50% - 10px);
            top: 50%;
          }
        }
      }
    }
  }
  #selected-menu-column {
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    .checked-icon {
      color: #399e0e;
      font-size: 20px;
      margin-right: 4px;
    }
    .selected-menu-list {
      flex-grow: 1;
      overflow: hidden;
      .selected-menu-list-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .ant-card-body {
          height: 100%;
          padding: 0;
          display: flex;
          flex-direction: column;
          .overview {
            padding: 16px;
          }
          .menu-list {
            flex-grow: 1;
            height: 100%;
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none;
            }
          }
          .ant-collapse {
            height: 100%;
            .ant-collapse-item {
              height: 100%;
              .ant-collapse-header {
                height: 46px;
              }
              .ant-collapse-content {
                height: calc(100% - 46px);
                .ant-collapse-content-box {
                  .collection-tag {
                    max-height: 78px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                  height: 100%;
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
          .ant-tag {
            position: relative;
            max-width: 100%;
            padding: 2px 8px;
            border-radius: 4px;
            background-color: #d9d9d9;
            margin: 0 4px 4px 0;
            > div {
              padding-right: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: rgba(0, 0, 0, 0.85);
              font-size: 14px;
            }
            > span {
              position: absolute;
              height: 100%;
              display: flex;
              top: 0;
              right: 0;
              align-items: center;
              justify-content: center;
              padding: 2px 4px;
              background-color: #d9d9d9;
            }
          }
        }

        .collection-select > .ant-select-selector {
          border: none;
        }
        .selected-menu-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 12px 16px;
          .menu-detail {
            margin-left: 8px;
            color: #000000a6;
            flex: 1;
            .menu-name {
              line-height: 20px;
              margin-bottom: 2px;
              word-break: break-word;
            }
            .price-dropdown {
              padding: 1px 8px 2px 6px;
              color: #fff;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 2px;
              cursor: pointer;
              &.ant-dropdown-trigger {
                background-color: #1890ff;
              }
              &.ant-dropdown-open {
                background-color: #0a6bd6;
              }
              .selected-price-name {
                max-width: 103px;
                color: #fff;
                margin-right: 4px;
                margin-bottom: 0;
              }
              .anticon {
                margin-left: 4px;
              }
            }
            .menu-note {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.25);
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;