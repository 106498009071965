#restaurant-list-container {
  .badge {
    margin-left: 4px;
    .ant-badge-count {
      color: #000000d9;
      border-radius: 4px;
      background: #00000026;
    }
  }
  .note {
    white-space: pre-line;
    word-break: break-word;
  }
}

.invoice-container {
  width: 100%;

  .invoice-selected-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .invoice-selected-dropdown {
      width: 100%;
      margin-top: 3px;
      margin-right: 16px;
    }

    a {
      display: none;
    }
  }
}
