#holiday-page {
  min-height: calc(100vh - @header-height);

  .warehouse-bg {
    padding: 0px 16px 16px 14px;
    background: #fff1b8;
  }
  .factory-bg {
    padding: 0px 16px 16px 14px;
    background: #d0dfe7;
  }
  .label {
    margin-bottom: 6px;
    color: #000000d9;
  }
  .radio-field {
    max-width: 250px;
    .radio-group {
      background: #23191614;
      padding: 2px;
      display: flex;
      > label {
        max-width: 50% !important;
        flex: 1;
      }

      .ant-radio-button-wrapper {
        background: initial;
        border: none;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #fff;
        color: #000000d9;
        border: none;

        &::before {
          background: #23191614;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .working-day-card {
    height: 100%;
    .ant-card-body {
      padding: 16px;

      .working-days-field {
        border-radius: 4px;
        .ant-legacy-form-item {
          margin-bottom: 0px;
        }
        .ant-checkbox-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: 30px;
          color: rgba(0, 0, 0, 0.85);
          &::after {
            content: "";
          }
        }
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;