#form-schedule-items {
  .ant-legacy-form-item {
    margin-bottom: 0;
  }
}
.create-in-app-message-page {
  min-height: 100%;
  display: flex;
  .bg-transparent {
    flex: 1;
    > .ant-card-body {
      padding-top: 0;
    }

    .ant-card-head {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .radio-field {
    max-width: 317px;
    .radio-group {
      background: #23191614;
      padding: 2px;
      display: flex;
      > label:last-child {
        max-width: 80% !important;
        flex: 1;
      }

      .ant-radio-button-wrapper {
        background: initial;
        border: none;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #fff;
        color: #000000d9;
        border: none;

        &::before {
          background: #23191614;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .ant-upload-select-picture-card, 
  .ant-upload-list-picture-card-container {
    width: 200px;
    height: 267px;
  }
  .ant-upload-list-item-thumbnail > .ant-upload-list-item-image {
    object-fit: cover;
  }

  .ant-upload-select-picture-card {
    padding: 10px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px;
  }
  .form-date-picker-field {
    width: 318px;
  }
  .attachment-type {
    width: 100%;
    margin-bottom: 0px;
    .ant-legacy-form-item-control {
      line-height: normal;
    }

    .radio-field {
      max-width: 100%;

      .radio-group {
        > label:nth-child(2) {
          max-width: 45% !important;
          flex: 1;
        }
      }
    }
  }
}


@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;