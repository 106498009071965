.status-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 149px;

  .status-content {
    margin-right: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);

    span {
      line-height: 16px;
      &:last-child {
        position: relative;
        bottom: 1.5px;
      }
    }
    .dot {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #04b459;
      display: inline-block;
      margin-right: 8px;
      &--inactive {
        @extend .dot;
        background-color: #6d7278;
      }
    }
  }

  .ant-switch-checked {
    background-color: #04b459 !important;
  }
}
