#status-switch-container {
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #04b459;
    display: inline-block;
    margin-right: 8px;
    &--inactive {
      @extend .dot;
      background-color: #6d7278;
    }
  }
  .status-display {
    margin: 0px 8px;
    font-weight: bold;
  }
  .ant-switch-checked {
    background-color: #04b459 !important;
  }
}

.product-detail-container {
  .ant-alert-error {
    padding: 12px 8px;
    .ant-alert-icon {
      font-size: 16px;
      margin-right: 8px;
      margin-top: 4px;
    }
  }
  .ant-page-header {
    .ant-avatar {
      margin-left: 8px;
    }
    .product-info {
      .inventory-container {
        display: flex;
        .status {
          color: #000000d9;
          font-weight: normal;
        }
        .status-container {
          margin-right: 8px;
        }
        button {
          margin-right: 8px;
        }
        .arrival-date {
          margin-right: 8px;
          font-weight: normal;
        }
      }
      .ant-typography {
        margin-bottom: 0px;
      }
      > div {
        font-size: 14px;
        > p {
          margin-bottom: 0px;
          color: #00000073;
          margin-right: 8px;
          margin-left: 4px;
          font-weight: normal;
        }
        .status-container {
          line-height: 21px;
          font-weight: normal;
        }
      }
    }
  }

  #background-filter-menu-total {
    margin-left: 4px;
    border-radius: 4px;
    background-color: #0000001a;
    padding: 0 4px;
  }
  .ant-tabs-tab:hover {
    #background-filter-menu-total {
      background-color: #1890ff1a;
    }
  }
  .ant-tabs-tab-active {
    #background-filter-menu-total {
      background-color: #1890ff1a;
    }
  }
}
