@import "../../styles/constants";

.clickable-row {
  &:hover {
    cursor: pointer;
  }
  div[aria-label="Copy"] i {
    padding: 2px 8px;
  }
}
.table-row-action {
  button {
    margin-right: $space-small;
  }
  button:last-child {
    margin-right: 0;
  }
}
.pagination-margin-right {
  .ant-table-pagination-right {
    margin-right: 16px;
  }
}
