@import "../../../../styles/constants";

.conversation-container {
  margin: -12px -16px !important;
  width: 400px;
  height: calc(100vh - 64px - 16px); // Approximate 100% - header-height - 16px(for free space)
  max-height: 1000px; // To make sure the height doesn't exceed 20 items per page
  .ant-card-head {
    height: 65px !important;
  }
  .ant-card-body {
    padding: 8px 0 !important;
    overflow: auto !important;
    height: calc(100% - 65px) !important; // 100% - ant-card-head_height
  }
  .conversation-item {
    padding: $space-smaller $space-smaller $space-x-smaller $space-smaller;
    .conversation-title {
      color: rgba(0, 0, 0, 0.85);
    }
    .conversation-message {
      color: rgba(0, 0, 0, 0.85);
      white-space: pre-line;
    }
    .conversation-time {
      color: rgba(0, 0, 0, 0.25);
    }
    &:hover {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.045),
        rgba(0, 0, 0, 0)
      );
    }
    &.unread {
      background-color: #1890ff1a;
    }
  }
  .conservation-loading {
    margin-top: $space-mediumer;
    text-align: center;
  }
}
