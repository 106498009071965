@import "../../../../../styles/constants";

#export-orders-form-action {
  margin-top: $space-small;
  border-top: 1px solid #e9e9e9;
  padding-top: $space-small;
}

#export-order-form {
  .ant-legacy-form-item {
    margin-bottom: 12px !important;
  }
}
