@import "../../../../../../styles/constants";

.ranking-update-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
      .ant-typography,
      .ant-typography p {
        margin-bottom: 0;
      }
      .menu-selector-container {
        padding: 24px;
        height: 100vh;
        max-height: 750px;
        display: flex;
        flex-direction: column;
        .filter-container {
          margin-bottom: 20px;
          > div {
            margin: 24px 0px 27px;
            .ant-form-item {
              margin-bottom: 0;
            }
          }
        }
      }
      .collection-menu-container {
        width: 100%;
        flex: 1;
        color: #000000d9;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .category-menu-list {
          overflow-y: scroll;
          flex: 1;
          height: 100%;
          border: solid 1px #00000026;
          border-radius: 4px;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
          .category-menu-item {
            color: #000000d9;
            text-align: left;
            width: 100%;
            border: none;
            border-bottom: solid 1px #00000026;
            font-size: 14px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            padding: 16px 12px 16px 7px;
            min-height: 44px;
            position: relative;
            &--selected {
              @extend .category-menu-item;
              background-color: #e6f7ff;
              background-blend-mode: multiply;
            }
            &--disabled {
              @extend .category-menu-item;
              color: #00000040;
              cursor: not-allowed;
              background: #0000000d;
            }
            &:focus {
              outline: none;
            }
            &:disabled {
              cursor: progress;
              color: #444444;
            }
            .checkmark {
              color: #1890ff;
              width: 12px;
              height: 12px;
              position: absolute;
              left: 8px;
            }
            .menu-item-detail {
              padding-left: 9px;
              width: calc(100% - 40px);
              overflow: hidden;
              .ant-checkbox-wrapper {
                margin-right: 16px;
              }
              .ant-avatar {
                margin-right: 8px;
              }
              .product-name {
                color: #000000d9;
              }

              .menu-item-note {
                color: #00000073;
                font-size: 12px;
                line-height: 12px;
                width: 100%;
                word-break: break-all;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
      .loading-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .selected-menu-container {
        display: block;
        border-radius: 4px;
        padding: 24px;
        background-color: #0000000d;
        .checked-icon {
          color: #399e0e;
          font-size: 16px;
          margin-right: 6px;
        }
        .selected-menu-bg {
          background-color: #ffffff;
          overflow: auto;
        }
        .selected-menu-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 12px 16px;
          border-bottom: solid 1px #00000026;
          &:last-child {
            border-bottom: none;
          }
          .menu-detail-container {
            flex: 1;
            overflow: hidden;

            .menu-detail {
              margin-left: 8px;
              color: #000000a6;
              font-size: 14px;
              flex: 1;
              overflow: hidden;

              .menu-name {
                line-height: 20px;
                margin-bottom: 2px;
              }
              .menu-note {
                font-size: 12px;
                color: #00000040;
                word-break: break-all;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }
      .selected-menu-container {
        display: flex;
        height: 100vh;
        max-height: 750px;
        flex-direction: column;

        > div:first-child {
          margin-bottom: 8px;
          > .ant-typography {
            font-size: 14px;
            font-weight: 600;
            color: #000000d9;
          }
        }
        .selected-menu-list {
          max-height: 675px;
          height: 100%;
          flex: 1;
          -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none;
            }
          border-radius: 4px;
          border: solid 1px #00000026;
          background: #fff;
          overflow: auto;
          
        }
      }
    }
  }
}
