#order-detail-container {
  min-height: calc(100vh - 64px);
  .order-header {
    line-height: 16px;
    margin-bottom: 4px;

    .child-order-list {
      .ant-space-item {
        line-height: 1;
      }
    }

    .order-id-list {
      .ant-space-item {
        line-height: 14px;
      }
      .order-id-label {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .order-id {
      font-size: 14px;
      font-weight: normal;
      padding: 0;
      height: 14px;
      line-height: 1;
    }
  }
  .supplier-info {
    .ant-card-head {
      font-size: 20px;
      color: #000;
      padding: 0 16px;
      margin: 0;
    }
    .ant-card-body {
      padding: 16px;

      .supplier-name {
        line-height: 1.2;
      }
    }
  }
  .text-title {
    font-size: 16px;
    font-weight: 500;
  }
  .text-bold {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
  }
  .text-secondary {
    font-size: 12px;
  }
  .order-edit-action {
    background-color: #f3f3f3;
    padding-bottom: 16px;
    border-bottom: 1px solid #0000000d;

    .ant-legacy-form-item {
      margin-bottom: 12px;
    }
  }
  .total-value {
    div:first-child {
      padding-bottom: 4px;
    }
  }
  .anticon {
    vertical-align: 0 !important;
  }
  .spacing-vertical > * {
    margin: 4px 0;
  }
  .negative-menu-color {
    color: #e62a2a;
  }
  .tracking-id-description {
    .description-item-border-bottom {
      border-bottom: 1px solid #e8e8e8 !important;
      padding-bottom: 16px;
    }
    .description-item-border-top {
      padding-top: 16px;
    }
  }
  .ant-collapse-header {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
    .ant-collapse-arrow {
      top: 40%
    }
  }
  .ant-collapse-content-active {
    .ant-alert {
      align-items: flex-start;
      margin-top: 12px;
    }
    .restaurant-info {
      line-height: 1.2;
      overflow: hidden;
      width: '70%';
      flex: 1;
      margin-left: 8px;
    }
  }

  .ant-table-row {
    width: 104px;
    margin-left: auto;
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }

    .ant-input-number-input {
      text-align: right;
    }
    .change-quantity {
      .ant-input-number-input-wrap {
        color: #08979c !important;
      }
    }

    .ant-input-number-group-wrapper .change-price {
      color: #08979c !important;
    }

    .ant-input-number-group-addon {
      display: none;
    }
    .ant-input-number {
      border: none;
      background-color: transparent;
    }

    &:hover {
      .ant-input-number {
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.15);
        background-color: transparent;
      }
    }

    &:focus-within {
      .ant-input-number-group-wrapper .change-price {
        color: rgba(0, 0, 0, 0.65) !important;
      }
      .change-quantity {
        .ant-input-number-input-wrap {
          color: rgba(0, 0, 0, 0.65) !important;
        }
      }
      .ant-input-number-group-addon {
        display: table-cell;
      }
      .ant-input-number {
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #1890ff, 0 0 0 2px rgba(24, 144, 255, 0.16);
      }
    }
  }

  .ant-table-thead > tr > th {
    width: 30px;
  }
  .ant-page-header-heading-left {
    margin: 0;
  }
  .ant-page-header-heading-extra {
    margin: 0;
  }
  #order-summary-list {
    .ant-list-item {
      align-items: flex-start;
    }
    .ant-list-item-meta-title {
      padding-right: 12px;
    }
    .ant-alert {
      align-items: flex-start;
      font-weight: normal;
      .ant-alert-icon {
        margin-top: 2px;
      }
    }
    .ant-alert-warning {
      background-color: #fff1b8;
      border: 1px solid #ee780f;
    }
  }
}

#order-detail-message {
  .submit-button {
    display: block;
    margin-left: auto;
  }
  .ant-form-item {
    margin-bottom: 8px !important;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none !important;
  }
  .ant-card-head {
    font-size: 20px; 
    color: #000;
    padding: 0 16px;
  }
  .ant-card-body {
    max-height: calc(480px - 58px);
    display: flex;
    flex-direction: column;
    padding-top: unset;
    padding: 16px;
  }
  .message-list {
    overflow-y: auto; 
    flex-grow: 1;
  }
}
