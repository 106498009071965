#basic-info,
.ant-btn-dangerous-wrapper {
  .ant-btn-dangerous.ant-btn-primary {
    background: #cf1421;
    padding: 6px 16px;
    gap: 10px;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px #00000040;
    box-shadow: 0px -1px 0px 0px #a8071a inset;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.27px;
    text-align: center;
    color: #ffffff;
  }

  .ant-btn-dangerous.ant-btn-primary[disabled] {
    opacity: 0.4;
  }
}
