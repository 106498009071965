.field-form-item {
  .ant-legacy-form-item-control {
    &.has-error {
      .ant-select-selector,
      .form-time-picker-field,
      .form-date-picker-field {
        border-color: #ff4d4f;
        &.ant-picker-focused {
          box-shadow: unset;
        }
        &.ant-select-focused {
          box-shadow: unset;
        }
      }
    }
  }
  .ant-legacy-form-item-label {
    min-height: 42px;
    line-height: unset;
    white-space: normal;
    padding-right: 8px;
    label::after {
      margin: 0 0 0 2px;
    }
  }
  &.ant-legacy-form-item-with-help {
    margin-bottom: 14px;
  }

  .ant-upload-picture-card-wrapper {
    &.round-image-field {
      .ant-upload-list-item,
      .ant-upload {
        border-radius: 50%;
        .ant-upload-list-item-info {
          border-radius: 50%;
        }
      }
    }
  }
}

.upload-field {
  
  .ant-upload-list-picture-card-container {
    width: 120px;
    height: 150px;
  }
  .ant-upload-select-picture-card {
    width: 120px !important;
    height: 120px !important;
    padding: 10px;

  }

  .ant-upload-list-item-list-type-picture-card {
    height: 120px !important;
    padding: 0px !important;
    border-radius: 4px;
    overflow: hidden; 

  }
}
.item-list-container {
  .item-list {
    background: #0000000d;
    padding: 4px;
    border-radius: 4px;
    .ant-list-items {
      .ant-list-item-action {
        margin-left: 8px;
      }
      .ant-list-item {
        background: #fff;
        border-radius: 4px;
        border: solid 1px #00000026;
        border-bottom: solid 1px #00000026;
        margin-bottom: 4px;
        padding: 16px;
        li {
          padding-right: 0;
        }
        button {
          color: #000000d9
        }
      }
      > li:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.select-field {
  .ant-legacy-form-item-control-wrapper {
    width: 50%;
    flex: 1;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;