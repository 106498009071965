#register {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background-color: @layout-body-background;

  #header-bar {
    z-index: 99;
    position: sticky;
    top: 0;
    box-shadow: inset 0 -0.5px 0 0 rgba(0, 0, 0, 0.15);
    height: 48px;
    padding: 6px 16px;
    background: @layout-body-background;
  }

  .email-alert {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff1b8;
    margin: 8px auto 0 auto;
    width: fit-content;
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .footer-bar {
      padding: 3px 0;
      box-shadow: inset 0 0.5px 0 0 rgba(0, 0, 0, 0.15);
      .info-link {
        color: rgba(0, 0, 0, 0.65);
        font-size: 12;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .container {
    position: relative;
  }
  .page {
    position: absolute;
  }
  .fade-from-left-enter {
    opacity: 0;
    transform: translateX(-50px);
  }
  .fade-from-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-from-left-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .fade-from-left-exit-active {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 300ms, transform 300ms;
  }

  .fade-from-right-enter {
    opacity: 0;
    transform: translateX(50px);
  }
  .fade-from-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-from-right-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .fade-from-right-exit-active {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 300ms, transform 300ms;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;