@import "../../../../../styles/constants";

#disable-account-action {
  margin-top: $space-small;
  border-top: 1px solid #e9e9e9;
  padding-top: $space-small;
  .form-button {
    width: 130px;
  }
}
