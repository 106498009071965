@import "../../../styles/constants";

#company-fields {
  .ant-legacy-form-item {
    margin-bottom: 0px !important;
  }
  #tracking-id-fields {
    .ant-legacy-form-item {
      margin-bottom: 8px !important;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
}

#tracking-id-action {
  padding-top: $space-small;
  margin-top: $space-medium;
  border-top: 1px solid #e9e9e9;
  .form-button {
    width: 100px;
  }
}

.tracking-id-Form {
  .ant-checkbox-wrapper-disabled {
    > span:last-child {
      color: #000000a6;
    }
  }
  .ant-checkbox-group > div {
    row-gap: 12px !important;
  }

  .ant-legacy-form-item {
    margin-bottom: 8px;
  }

  .ant-legacy-form-item-control {
    line-height: normal;
  }

  #tracking-id-fields > div {
    padding: 4px 0px;
  }
}

.tracking-field {
  transition: background 0.5s linear;
  border-radius: 4px;

  .ant-btn-link  {
    width: 18px;
    min-width: 18px;
  }
}
