.tag-header {
    line-height: 25px;
  .title {
    font-size: 20px;
  }
  .status-container {
    font-size: 14px;
  }
}

.tag-form {
  .ant-legacy-form-item-label {
    width: 80px;
  }
}
