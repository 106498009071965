@import "../../../../../styles/_constants.scss";

.supplier-brand-create-model {
  width: 35% !important;
}

#supplier-brand-create-action {
    margin-top: $space-small;
    border-top: 1px solid #e9e9e9;
    padding-top: $space-small;
    .form-button {
      width: 100px;
    }
  }