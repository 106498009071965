.ranking-detail-page {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  .bg-transparent {
    flex: 1;
  }
  .ranking-menu-container {
    .ant-card-body {
      padding: 0 0 16px 16px;

      .note {
        margin-right: 16px;
        margin-top: 16px;
        max-width: 100%;
        color: #000000d9;
      }
    }
  }

  .ant-alert-warning {
    border-color: #d48805;
    background-color: #fff1b8;
  }

  .status-wrapper {
    display: flex;
    flex-direction: column;
    .status::before {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #04b459;
      display: inline-block;
      margin-right: 8px;
      content: " ";
    }
    .status {
      color: #000000a6;
    }

    .sold-out::before {
      background-color: #f5222d;
    }
    
    .inactive::before {
      background-color: #00000073;
    }
  }

  #rankingForm {
    .ant-card-body {
      padding: 16px;
    }
    .ant-legacy-form-item-label {
      width: 100px;
      white-space: normal;
      line-height: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
    .display-toggle {
      .ant-legacy-form-item-label {
        padding-right: 9px;
      }
    }
    .status-container {
      align-items: flex-start;
      margin-left: 4px;
      > div:first-child {
        margin-right: 8px;
        line-height: normal;
      }
    }
    .specify-field {
      border-radius: 4px;
      border: solid 1px #00000026;
      background-color: #0000000d;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 4px;
      margin-right: 24px;
      .tag {
        background: #d0dfe7;
        padding: 2px 8px;
        line-height: 22px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;