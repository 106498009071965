#ranking-list-container {
    position: relative;
    width: 100%;
    .note {
      margin-bottom: 0;
    }
    .ranking-numbers {
      margin-top: 55px;
      justify-content: space-around;
      align-items: center;
      width: 50px;
      padding-right: 16px;
      
      .item {
        width: 24px;
        height: 24px;
        font-weight: bold;
        background: #000000d9;
        color: white;
        text-align: center;
        border-radius: 50%;
      }
    }
    @media screen and (max-width: 1759px) {
      .ranking-numbers {
        margin-bottom: 8px;
      }
    }
  }

.ranking-list-page {
    .ant-card-head-title {
        font-size: 20px;
        font-weight: bold;
        color: #000;
    }
    .ant-card-body {
        padding-top: 0;
    }
    .ant-table-content {
      &::-webkit-scrollbar {
        height: 8px;
        background-color: #fafafa;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        height: 4px;
        background-color: #c1c1c1
      }
  }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;