#product-list-container {
  #count-filter-status {
    font-size: 24px;
    font-weight: bold;
  }
  .price {
    color: #000000d9;
    font-size: 14px;
  }

  .ant-select-selection-item {
    color: #00000073;
  }

  .label {
    color: #000000d9;
  }

  .original-price {
    text-decoration: line-through; 
    font-size: 12px; 
    color: #00000073
  }
  .menu-type-filter {
    .ant-tabs-tab {
      padding: 16px 0;
      color: #00000040;
      #count-filter-status {
        color: #00000073;
        font-weight: bold;
      }

      .basic-plan-product {
        padding-left: 32px;
        border-left: 1px solid #00000026;
      }
    }
    .ant-tabs-tab-active {
      #count-filter-status {
        color: #1890ff;
        font-weight: bold;
      }
    }
  }
  .anticon {
    vertical-align: 0 !important;
  }
  .inventory-container {
    button {
      margin-left: 18px;
    }
  }
  .order-status-container {
    .ant-card-body {
      padding: 0 16px;
      .ant-tabs-nav {
        margin-bottom: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  #ranking-list-container {
    position: relative;
    width: 100%;
    .ranking-numbers {
      margin-top: 55px;
      justify-content: space-around;
      align-items: center;
      width: 50px;
      padding-right: 24px;
      .item {
        width: 24px;
        height: 24px;
        font-weight: bold;
        background: #000000d9;
        color: white;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #04b459;
    display: inline-block;
    margin-right: 8px;
    &--inactive {
      background-color: #00000073;
    }
  }
  .error {
    background-color: #cf1421;
  }
  button {
    margin-left: 8px;
  }
}

.ant-switch-checked {
  background-color: #04b459 !important;
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;