#sider-container {
  z-index: 100;
  box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.15);
  background: @layout-body-background;
  .collapse-btn-container {
    position: absolute;
    top: 0;
    right: -36px;
    height: @header-height;
    display: flex;
    align-items: center;
    .collapse-icon {
      font-size: 20px;
    }
  }
  .menu-container {
    position: sticky;
    top: 0;
    .logo-container {
      height: @header-height;
      margin-left: 20px;
    }
    .ant-menu {
      .ant-menu-item-selected {
        background-color: #dbe9f9;
      }
      border: none;
      background: @layout-body-background;
      box-shadow: inset -0.5px 0 0 0 rgba(0, 0, 0, 0.15);
    }

    .ant-menu-item.ant-menu-item-selected {
      img {
        filter: invert(41%) sepia(75%) saturate(2099%) hue-rotate(190deg) brightness(101%) contrast(101%);
      }
      .ant-menu-title-content {
        .invitations-restaurant {
          color: #1890ff;
        }
      }
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title {
      img {
        filter: invert(41%) sepia(75%) saturate(2099%) hue-rotate(190deg) brightness(101%) contrast(101%);
      }
      .ant-menu-title-content {
        .invitations-restaurant {
          color: #1890ff;
        }
      }
    }

    .ant-menu-item {
      &:hover {
        img {
          filter: invert(41%) sepia(75%) saturate(2099%) hue-rotate(190deg) brightness(101%) contrast(101%);
        }
      }
    }

    .restaurants-item {
      .ant-menu-title-content {
        .invitations-restaurant {
          color: gray;
        }
        &:hover {
          .invitations-restaurant {
            color: #1890ff;
          }
        }
        p {
          margin-bottom: 0;
          &:last-child {
            font-size: 12px;
            display: flex;
            align-items: center;

            .ant-badge-status-text {
              margin-left: 0;
            }

            .invitations-count {
              .ant-badge-count {
                font-weight: bold;
                color: #1890ff;
                background: transparent;
                padding: 0 2px;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
  .build-number-container {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #e9e9e9;
    padding: 5px;
    width: 100%;
    font-size: 10px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;