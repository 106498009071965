#product-create-form {
  .form-item-no-margin {
    .ant-legacy-form-item {
      margin-bottom: 0;
    }
  }
  .tag-field-wrapper {
    > div:first-child {
      margin-bottom: 0px;
    }
  }
  .empty-tag-field {
    > div:first-child {
      margin-bottom: 24px;
    }
  }
  #isAllowDownloadImageField > .ant-legacy-form-item {
    margin-bottom: 0;
  }
  .tag-field {
    .ant-select-selector {
      border: none;
      box-shadow: none;
    }
  }
  #add-price-button {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
  .radio-field {
    .radio-group {
      background: #23191614;
      padding: 2px;

      .ant-radio-button-wrapper {
        background: initial;
        border: none;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: #fff;
        color: #000000d9;
        border: none;

        &::before {
          background: #23191614;
        }
      }
    }
  }
  .basic-field {
    .radio-button {
      min-width: 50%;
      padding: 0px 4px !important;
    }
  }
  #productOrganization {
    .product-type-field {
      .radio-button {
        min-width: 50%;
        padding: 0px 4px !important;
      }
    }
    .ant-legacy-form-item {
      margin-bottom: 16px;
    }
    .ant-legacy-form-item-control {
      line-height: normal;
      width: 100%;
    }
    .ant-legacy-form-item-control-wrapper {
      display: flex;
      align-items: center;
    }
    .ant-legacy-form-item-label {
      white-space: normal;
      line-height: normal;
      padding-right: 8px;
    }
    .red {
      color: #cf1421;
    }
    .green {
      color: #399e0e;
    }
    .orange {
      color: #ee780f;
    }
    .solOut-field {
      .ant-switch-checked {
        background: #399e0e;
      }
      .ant-legacy-form-item-label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .status {
      display: flex;
      align-items: center;
      margin-right: 4px;
      &::before {
        content: " ";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #399e0e;
        margin-right: 4px;
      }
    }
    .sold-out {
      &::before {
        background-color: #cf1421;
      }
    }
    .in-stock {
      &::before {
        background-color: #399e0e;
      }
    }
    .syncing {
      color: #ee780f;
      &::before {
        background-color: #ee780f;
      }
    }
    .form-date-picker-field {
      width: 100%;
    }
  }

  .price-field {
    > .ant-legacy-form-item-control-wrapper {
      overflow: hidden;
    }
    .ant-table-tbody > tr:last-child {
      box-shadow: 0 -4px 4px 0 #00000026 !important;

      > td:last-child {
        box-shadow: 0 -4px 4px 0 #00000020;
      }
    }
  }
}

#product-create-anchor {
  .ant-anchor-ink-ball {
    width: 2px;
    height: 40px;
    transform: translateY(-15.5px);
  }
  .anchor-item {
    height: 40px;
    display: flex;
    align-items: center;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@body-background: #fff;@component-background: #fff;@layout-body-background: #f2f4f9;@header-height: 64px;@space-x-smaller: 4px;@space-x-small: 8px;@space-smaller: 12px;@space-small: 16px;@space-mediumer: 20px;@space-medium: 24px;@space-larger: 28px;@space-large: 32px;@space-x-larger: 36px;@space-x-large: 40px;@space-xx-larger: 44px;@space-xx-large: 48px;@space-xxx-larger: 52px;@font-x-smaller: 8px;@font-x-small: 10px;@font-smaller: 12px;@font-small: 14px;@font-mediumer: 16px;@font-medium: 18px;@font-larger: 20px;@font-large: 22px;@font-x-larger: 24px;@font-x-large: 26px;@font-xx-larger: 28px;@font-xx-large: 30px;@btn-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);@btn-primary-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 #0a6bd6;