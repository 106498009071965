html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Noto Sans JP", Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: "Noto Sans JP", sans-serif;
}

#app {
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.5em;
}

.ant-descriptions {
  .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-descriptions-item-content {
    color: rgba(0, 0, 0, 0.85);
  }
}
.ant-descriptions-bordered {
  .ant-descriptions-view,
  .ant-descriptions-row {
    border: none !important;
  }
  .ant-descriptions-item-label {
    white-space: nowrap !important;
    text-align: left !important;
    border: none !important;
    padding: 8px 8px 8px 0 !important;
    background: transparent !important;
    white-space: nowrap !important;
  }
  .ant-descriptions-item-content {
    width: 100% !important;
    border: none !important;
    padding: 8px 0 8px 8px !important;
    white-space: unset !important;
  }
}

.bg-transparent {
  background: transparent !important;
}
.full-w {
  width: 100%;
}
.flex {
  display: flex !important;
  &.direction-column {
    flex-direction: column;
  }
  &.items-center {
    align-items: center;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.items-start {
    align-items: flex-start;
  }
}
.block {
  display: block;
}
.cursor-pointer {
  cursor: pointer;
}
.highlight-card {
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.max-width-644 {
  max-width: 665px;
}
.status-container .ant-space-item {
  > .active {
    background: #f6ffec;
    .ant-typography {
      color: #04b459;
    }
    &::before {
      background-color: #04b459;
    }
  }

  > div {
    background: rgba(0, 0, 0, 0.15);
    padding: 2px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    &::before {
      content: " ";
      width: 16px;
      height: 16px;
      background-color: #00000073;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }
    .ant-typography {
      color: #00000073;
    }
  }
}

.ant-notification-notice {
  padding: 12px 16px 16px 16px;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-icon {
  margin-left: 0;
  font-size: unset;
  line-height: unset;
}
.ant-notification-notice-message {
  margin-top: 4px;
  margin-bottom: 0 !important;
}
.ant-notification-notice-description {
  margin-top: 4px;
  line-height: 24px;
  padding-right: 24px;
  white-space: pre-line;
}
.ant-notification-notice-close {
  right: 16px !important;
}

.padding-card {
  overflow: hidden;
  .ant-card-body {
    padding: 0;
  }
}
.padding-card-16 {
  .ant-card-body {
    padding: 16px;
    overflow: hidden;
  }
}
.padding-16 {
  padding: 16px;
}

.search-input .ant-btn {
  box-shadow: none;
}

.ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.ant-table {
  border-radius: 0px;
}

.ant-table-thead > tr > th {
  background: #f3f3f3;
}

.ant-table-container table > thead > tr:first-child {
  th:first-child {
    border-top-left-radius: 0px;
  }
  th:last-child {
    border-top-right-radius: 0px;
  }
} 
.ant-btn {
  box-shadow: none;
}

.restaurant-info-wrapper {
  flex: 1;
  width: 70%;
  overflow: hidden;
  margin-left: 4px
}

.divider {
  height: 32px;
  display: flex;
  align-items: center;

  > .ant-divider {
    position: absolute;
    left: 16px;
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }

}

