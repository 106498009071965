$primary-color: #e8b900;

$header-height: 64px;

$space-x-smaller: 4px;
$space-x-small: 8px;
$space-smaller: 12px;
$space-small: 16px;
$space-mediumer: 20px;
$space-medium: 24px;
$space-larger: 28px;
$space-large: 32px;
$space-x-larger: 36px;
$space-x-large: 40px;
$space-xx-larger: 44px;
$space-xx-large: 48px;
$space-xxx-larger: 52px;

$font-x-smaller: 8px;
$font-x-small: 10px;
$font-smaller: 12px;
$font-small: 14px;
$font-mediumer: 16px;
$font-medium: 18px;
$font-larger: 20px;
$font-large: 22px;
$font-x-larger: 24px;
$font-x-large: 26px;
$font-xx-larger: 28px;
$font-xx-large: 30px;