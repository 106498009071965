@import "../../../../styles/constants";

.notification-container {
  margin: -12px -16px !important;
  width: 360px;
  height: calc(100vh - 64px - 16px); // Approximate 100% - header-height - 16px(for free space)
  max-height: 1000px; // To make sure the height doesn't exceed 20 items per page
  .ant-card-head {
    padding: 0 16px;
    border-bottom: none;
    .notification-header {
      display: flex;
      flex-direction: column;

      .ant-radio-group {
        width: 100%;
        padding: 2px;
        background: #23191614;

        .ant-radio-button-wrapper {
          width: 50%;
          text-align: center;
          background: initial;
          border: none;
          color: #000000d9;
        }
        .ant-radio-button-wrapper-checked {
          background: #fff;
          border-color: #fff;
          color: #000000d9;
          box-shadow: none;
          &::before {
            background: none;
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 0 !important;
    overflow: auto !important;
    height: calc(100% - 65px) !important; // 100% - ant-card-head_height

    .notification-badge {
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: -8px;
    }
  }
  .ant-card-head {
    .ant-card-extra {
      .extra-button {
        padding: 0;
      }
    }
  }
  .notification-item {
    padding: $space-smaller $space-x-small;
    .notification-message {
      font-size: 14px;
      margin-bottom: -4px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: normal;
      white-space: break-spaces;
    }
    .notification-time {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
    .notification-action {
      margin-left: $space-x-smaller;
      text-align: center;
      font-size: 14px;
      visibility: hidden;
    }
    &:hover {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.045),
        rgba(0, 0, 0, 0)
      );
      .notification-action {
        visibility: visible !important;
      }
    }
    &.unread {
      background-color: #e6f4ff;
    }
  }
  .notification-loading {
    margin-top: $space-mediumer;
    text-align: center;
  }
}
