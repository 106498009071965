.manufacturer-detail-container {
  .ant-page-header {
    .manufacturer-info {
      > div {
        font-size: 14px;
        > p {
          margin-bottom: 0px;
          color: rgba(0, 0, 0, 0.45);
          margin-right: 8px;
        }
        .status-container {
          line-height: 21px;
        }
      }
    }
  }

  .ant-page-header-footer {
    margin-top: 16px;
  }
  .ant-page-header-heading-left {
    margin: 0;
  }
  .ant-page-header-heading-extra {
    margin: 0;
  }
}
